@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi", sans-serif;
}

@layer base {
  body {
    @apply bg-white;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Satoshi", sans-serif;
  margin: 0;
}

@layer components {
  .border-gradient {
    position: relative;
  }

  .border-gradient::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: -1;
    background: linear-gradient(90deg, #196788 0%, rgba(120, 59, 249, 0) 100%);
    border-radius: inherit;
  }
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
